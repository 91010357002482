import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import { Button, Modal, Segment, Sidebar } from 'semantic-ui-react';
import OutsideClickHandler from '@htmniseko/htm-components/OutsideClickHandler';
import { close } from '@htmniseko/htm-ui/DatePicker';
import { logout } from 'redux/modules/auth';
import { push } from 'redux/modules/router';
import { hide } from 'redux/modules/dialog';
import DatePicker from 'components/DatePicker/DatePicker';
import Dialog from 'components/Dialog/Dialog';
import Footer from 'components/Footer/Footer';
import Loader from 'components/Common/Loader';
import { ClientManager, Header, MobileSidebar, NavigationBar, NotificationManager, Popup } from 'containers';
import ChildrenRoutes from './ChildrenRoutes';
import IdleTimer from './IdleTimer';
import constants from '../../constants';
import 'fomantic-ui/dist/semantic.min.css'; // eslint-disable-line import/extensions

const REDIRECT_LINK = '/financial/currentStatus';

const getRedirectUrl = query => {
  if (!query.nextURL) {
    return REDIRECT_LINK;
  }

  const decodedURL = decodeURIComponent(query.nextUrl);
  // If decodeURIComponent is unable to decode anything, it will return 'undefined' as string
  if (decodedURL !== 'undefined') {
    return decodedURL;
  }

  return query.nextURL;
};

export class App extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    route: PropTypes.object,
    close: PropTypes.func.isRequired,
    dialog: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    loaderVisible: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    screenType: PropTypes.string.isRequired,
    tenant: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  state = {
    displaySidebar: false,
    isReady: false,
  };

  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  componentDidMount() {
    this.setState({ isReady: true });
  }

  componentDidUpdate(prevProps) {
    const { auth: { admin, token, user, sent2faCode }, push } = this.props;
    const { auth: { sent2faCode: previousSent2faCode } } = prevProps;
    // Handling admin 2FA authentication
    if (!previousSent2faCode && sent2faCode && !token) {
      push('/verify2faCode');
    }
    // If admin reselect an owner
    else if (prevProps.auth.admin && user && !isEqual(prevProps.auth.user, user)) {
      push(REDIRECT_LINK);
    }
    // Handling user and admin login
    else if ((!prevProps.auth.user && user) || (!prevProps.auth.admin && admin)) {
      // Manually start the timer only after a user has logged in
      // Don't start the timer automatically neither when the component mounts because a page refresh will trigger a timeout and logged out the user
      this.idleTimer.start();

      // Redirect admin to choose an owner ID to begin the session
      if (!user && admin) {
        push('/selectOwnerId');
      }
      else {
        const { query } = prevProps.location;
        // Auto redirect user to the path if defined in the URL
        const nextURL = getRedirectUrl(query);
        push(nextURL);
      }
    }
  }

  onIdle() {
    // End session if the user is idle
    const { auth: { user }, logout } = this.props;
    if (user) {
      logout();
    }
  }

  toggleSidebar() {
    this.setState(prevState => ({ displaySidebar: !prevState.displaySidebar }));
  }

  closeSidebar() {
    this.setState({ displaySidebar: false });
  }

  render() {
    const { displaySidebar, isReady } = this.state;
    const { auth: { user }, route, close, dialog, hide, isOpen, loaderVisible, screenType, tenant } = this.props;
    const { title, text, messageType, visible } = dialog;
    const { head: { titleTemplate, meta } } = tenant.config;
    const showSidebar = screenType === 'tablet' || screenType === 'mobile';

    return (
      <IntlProvider>
        <Helmet titleTemplate={titleTemplate} meta={meta} />
        <IdleTimer
          ref={ref => { this.idleTimer = ref; }}
          crossTab
          timeout={constants.idleTimeout}
          syncTimers
          onIdle={() => this.onIdle()}
        />
        { (!isReady || loaderVisible) && <Loader />}
        { isReady && (
          <>
            {/* Menu sidebar for mobile view */}
            <OutsideClickHandler onOutsideClick={() => this.closeSidebar()}>
              <MobileSidebar visible={displaySidebar} closeSidebar={() => this.closeSidebar()} />
            </OutsideClickHandler>
            {
              // Floating mobile menu icon
              showSidebar && user && (
                <Button
                  basic
                  circular
                  icon="sidebar"
                  onClick={() => this.toggleSidebar()}
                  style={{ zIndex: 5, position: 'fixed', top: '10px', left: '10px' }}
                />
              )
            }
            <Sidebar.Pushable as={Segment} style={{ border: 0, borderRadius: 0, minHeight: '100vh', margin: 0 }}>
              {/* Module for updating screen size information */}
              <ClientManager />
              {/* Datepicker */}
              <Modal open={isOpen} onClose={() => close()} size="mini">
                <Modal.Content style={{ maxWidth: '350px', margin: '0 auto' }}>
                  <DatePicker />
                </Modal.Content>
              </Modal>
              {/* Module for displaying notifications */}
              <NotificationManager />
              {/* Module for displaying pop-up message */}
              <Popup />
              {/* Module for displaying message in a full screen dialog */}
              <Dialog
                open={visible}
                title={title}
                message={text}
                type={messageType}
                onClose={() => hide()}
              />
              {/* Main content */}
              <Sidebar.Pusher style={{ position: 'initial' }}>
                { !showSidebar && <Header /> }
                { !showSidebar && user && <NavigationBar /> }
                {/* Child routes */}
                <ChildrenRoutes route={route} />
                { !showSidebar && <Footer /> }
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </>
        )}
      </IntlProvider>
    );
  }
}

const ConnectedApp = connect(state => ({
  auth: state.auth,
  dialog: state.dialog,
  loaderVisible: state.app.loaderVisible,
  location: state.router.location,
  isOpen: state.datepicker.open,
  screenType: state.client.screenType,
  tenant: state.tenant,
}), { close, hide, logout, push })(App);
export default ConnectedApp;
