import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Button, Form, Popup } from 'semantic-ui-react';
import FormField from 'components/Field/FormField';
import ThemeLinkButton from 'components/Theme/ThemeLinkButton';

const TwoFactorAuthForm = ({ isAdmin, onSubmit, onResend2FACode }) => {
  const { formatMessage } = useIntl();
  const subtitleId = isAdmin ? 'page.user.twoFactorAuth.subtitle.admin' : 'page.user.twoFactorAuth.subtitle.user';

  return (
    <Form onSubmit={onSubmit} style={{ maxWidth: '400px', margin: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>
          <FormattedMessage id={subtitleId} />
        </p>
        <FormField
          required
          id="twoFaCode"
          element={Form.Input}
          label={formatMessage({ id: 'page.user.twoFactorAuth.form.label.twoFaCode' })}
          style={{
            width: '100px',
          }}
        />
        <Popup
          position="bottom left"
          content={formatMessage({ id: 'page.user.twoFactorAuth.checkbox.popup.rememberMe' })}
          trigger={(
            <FormField
              id="trustDevice"
              element={Form.Checkbox}
              label={formatMessage({ id: 'page.user.twoFactorAuth.checkbox.label.rememberMe' })}
            />
          )}
        />
        <ThemeLinkButton titleId="page.user.twoFactorAuth.retry" onClick={onResend2FACode} />
        <Button type="submit" color="green" size="large" style={{ maxWidth: '100px', margin: '25px auto' }}>
          <FormattedMessage id="general.submit" />
        </Button>
      </div>
    </Form>
  );
};

TwoFactorAuthForm.propTypes = {
  isAdmin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onResend2FACode: PropTypes.func.isRequired,
};

TwoFactorAuthForm.defaultProps = {
  isAdmin: false,
};

export default reduxForm({ form: 'twoFactorAuth' })(TwoFactorAuthForm);
