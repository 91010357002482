import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Label } from 'semantic-ui-react';

@connect(state => ({ aStyles: state.tenant.theme.a }))
export default class ThemeLinkButton extends Component {
  static propTypes = {
    aStyles: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    titleId: PropTypes.string.isRequired,
    size: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    size: 'large',
  };

  render() {
    const { aStyles, onClick, size, style, titleId } = this.props;
    return (
      <Label
        as="a"
        basic
        size={size}
        onClick={onClick}
        style={{
          border: 'none',
          display: 'block',
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
          ...aStyles,
          ...style,
        }}
      >
        <FormattedMessage id={titleId} />
      </Label>
    );
  }
}
