import { setAuthCookie, setTrustedDeviceCookie } from 'utils/cookies/setCookies';
import { refresh } from './actions';
import types from './types';

const { AUTH_CALLBACK, LOGIN_SUCCESS, LOGOUT_SUCCESS, REFRESH_SUCCESS, VERIFY_SUCCESS } = types;
/*
  This middleware monitors the verify 2FA endpoint and set trusted device to cookies whenever trusted device info is returned.
*/
let timer = null;

export default ({ dispatch }) => next => action => {
  const { result, type } = action;

  const dispatchRefresh = () => {
    // Use the Session Token TTL to set the timeout to call the refresh token action
    // The TTL is in seconds, convert it to milliseconds and reduce 30 seconds to avoid timing issues
    const idleTimeOut = (result.ttl - 30) * 1000;
    timer = setTimeout(() => dispatch(refresh()), idleTimeOut);
  };

  switch (type) {
    case AUTH_CALLBACK:
    case LOGIN_SUCCESS:
    case REFRESH_SUCCESS: {
      if (!result.sent2faCode) {
        // The session already started if no 2FA code was sent
        setAuthCookie(result);
        dispatchRefresh();
      }
      break;
    }
    case LOGOUT_SUCCESS: {
      clearTimeout(timer);
      break;
    }
    case VERIFY_SUCCESS: {
      const { trustedDevice } = result;
      if (trustedDevice) {
        setTrustedDeviceCookie(trustedDevice);
      }
      setAuthCookie(result);
      dispatchRefresh();
      break;
    }
    default: {
      next(action);
      return;
    }
  }
  next(action);
};
