/*
  Global configurations, applies for all tenants
*/

module.exports = {
  name: 'HTM Owners Portal',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8880,
  api: {
    host: process.env.APIHOST || 'http://localhost:3335',
  },
};
