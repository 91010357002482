import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Message } from 'semantic-ui-react';
import { display } from 'redux/modules/dialog';
import { push } from 'redux/modules/router';
import { login } from 'redux/modules/auth';
import ThemeLinkButton from 'components/Theme/ThemeLinkButton';
import ThemeButton from 'components/Theme/ThemeButton';

export class Login extends PureComponent {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    display: PropTypes.func.isRequired,
    tenantId: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleKeyDown(event) {
    // Do login when key "Enter" is pressed
    if (event.keyCode === 13) {
      this.handleSubmit(event);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { intl: { formatMessage }, display, login, tenantId } = this.props;
    const user = this.username.inputRef.current;
    const pass = this.password.inputRef.current;
    const username = user.value.trim().toLowerCase();
    const password = pass.value.trim();

    if (username === '') {
      display(
        formatMessage({ id: 'page.login.message.informationRequired' }),
        formatMessage({ id: 'page.login.message.usernameRequired' }),
        'warning'
      );
    }
    else if (password === '') {
      display(
        formatMessage({ id: 'page.login.message.informationRequired' }),
        formatMessage({ id: 'page.login.message.passwordRequired' }),
        'warning'
      );
    }
    else {
      login(username, password, tenantId);
      // Clearing the password field everytime after submit
      pass.value = '';
    }
  }

  render() {
    const { authStore: { error }, intl: { formatMessage } } = this.props;
    return (
      <Form style={{ maxWidth: '320px', margin: '0 auto' }}>
        {
          error && error.message && (
            <Message error style={{ display: 'block' }}>
              <p>{error.message}</p>
            </Message>
          )
        }
        <Form.Group style={{ display: 'block' }}>
          <Form.Field>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="login-user" style={{ margin: '10px 0' }}>
              <FormattedMessage id="page.login.label.username" />
            </label>
            <Input
              fluid
              size="large"
              id="login-user"
              name="username"
              ref={input => { this.username = input; }}
              placeholder={formatMessage({ id: 'page.login.label.username' })}
              onKeyDown={ev => this.handleKeyDown(ev)}
              autoCapitalize="none"
              autoCorrect="off"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group style={{ display: 'block' }}>
          <Form.Field>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="login-pass" style={{ margin: '10px 0' }}>
              <FormattedMessage id="page.login.label.password" />
            </label>
            <Input
              fluid
              size="large"
              id="login-pass"
              name="password"
              ref={input => { this.password = input; }}
              type="password"
              placeholder={formatMessage({ id: 'page.login.label.password' })}
              onKeyDown={ev => this.handleKeyDown(ev)}
            />
          </Form.Field>
        </Form.Group>
        <ThemeButton
          fluid
          titleId="page.login.action.login"
          onClick={ev => this.handleSubmit(ev)}
          style={{ margin: '20px 0' }}
        />
        <ThemeLinkButton
          titleId="page.login.label.forgotPassword"
          onClick={() => this.props.push('/forgotPassword')}
        />
      </Form>
    );
  }
}

const withIntl = injectIntl(Login);

export default connect(state => ({ authStore: state.auth, tenantId: state.tenant.id }), { display, login, push })(withIntl);
